.topbar {
    display: flex;
    border-bottom: 1px solid #eee;
  
    width: 1250px;
    text-align: left;
    padding: 10px;
    margin: auto;
    margin-bottom: 14px;
    background-color: #ffffff;
    /* background-image: url("https://www.transparenttextures.com/patterns/arabesque.png"); */
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }
  
  .topbar-left {
    flex: 1
  }
  
  .topbar a {
    line-height: 1;
    color: #555;
  }
  
  .topbar .topbar-left i {
    color: #a3870a;
    padding: 4px;
  }
  
  .top-bar-border-left {
    padding-left: 20px !important;
    margin-left: 20px;
    border-left: 1px solid #e9e9e9;
  }
  
  .topbar .topbar-right a {
    color: #555;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    border-left: 1px solid #e9e9e9;
  }