.left-menu {
    height: 100vh;
    width: 80px;
    /* background-color: #dd4b39; */
    /* color: rgb(255, 255, 255); */
    color: #033c65;
    background-color: white;
}

.left-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left-menu li {
    width: 100%;
    height: 60px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    /* background-color: aqua; */
}

.left-menu li:hover {
    background-color: #fbf7f7;
}

.left-menu-active {
    border-left: 5px solid var(--primary-color);
    color: var(--primary-color-darker);
    padding-left: 25px;
    align-items: unset !important;
    /* background-color: #d5301c; */
    /* color: rgb(255, 255, 255); */
}

.left-logo-top {
    /* background-color: #565653; */
    height: 70px;
    /* color: white; */
    color: #111;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 22px;
}

.home-parent {
    display: flex;
    padding-top: 0;
    overflow: scroll;
}

.main-content {
    flex: 1;
    padding-left: 60px;
    padding-right: 60px;

    background-color: #fdf9f3;
    /* #fdf9f3;#f4f5f7*/
}

.main-header {
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;
}

.main-heading {
    height: 32px;
}

.main-header-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    column-gap: 28px;
}

.home-parent h1 {
    color: #111;
    text-align: left;
    padding-left: 10px;
    font-size: 28px;
}

.main-content-inner {
    flex: 1;
    display: flex;
    column-gap: 20px;
    padding-top: 10px;
}

.content-block {
    flex: 1;
    border-radius: 15px;
}

.upload-profile-picture {
    height: 150px;
    width: 150px;
    border: 3px dotted #eee;
    border-radius: 500px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
}

.upload-profile-picture div {
    height: 50px;
    width: 50px;
    color: white;
    font-size: 23px;
    border-radius: 100px;
    background-color: var(--primary-color-darker);
    cursor: pointer;
}


.bottom-line {
    border-bottom: 1px solid #b5b5b5;
}

.bottom-line input {
    border: 0;
    padding: 10px;
    width: 220px;
    font-size: 14px;
    margin-top: 20px;
}

.side-by-side {
    display: flex;
    width: 500px;
    margin-top: 20px;
    column-gap: 20px;
}

.side-by-side input {
    border: 0;
    padding: 10px;
    width: 300px;
    /* border-bottom: 1px solid #b5b5b5; */
    background-color: #eee;
    border-radius: 5px;
}

.side-title {
    flex: 1;
    font-size: 20px;
    text-align: right;
    font-weight: 700;
    text-transform: capitalize;
    padding-top: 10px;
}

.but {
    background-color: #ff8400;
    height: 40px;
    width: 150px;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.but:hover {
    background-color: #eee !important;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.button-parent {
    display: flex;
    justify-content: right;
    align-items: end;
    width: 100%;
    padding-right: 70px;
    margin-top: 35px;

}

.title-thin {
    font-weight: 300;
    color: #868383;
}

.profile-small {
    height: 40px;
    width: 40px;
    background-color: #b5b5b5;
    border-radius: 100px;
    background-image: url(../../assets/profile.png);
    background-size: cover;
}

.stats-section {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    height: 100px;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
}

.stats-section li {
    height: 100px;
    background-color: white;
    border-radius: 20px;
    /* display: flex;
    flex-direction: column;
    align-items: left; */
    padding: 20px;
}

.stats-section li span {
    display: inherit;
}

.stats-section .title {
    display: inherit;
    color: #868383;
}

.stats-section .value {
    font-size: 40px;
}

.stats-section .ver-flex {
    flex: 1
}

.form-parent {
    padding: 0 30px
}

.up-down {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 15px;
}

.up-title {
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.up-down > input, select,textarea {
    border: 1px solid #eee;
    padding: 10px 10px;
    border-radius: 5px;
}

.admin-controls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    height: 354px;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    padding: 38px 70px;
    border-radius: 20px;
}

.admin-controls li {
    padding-top: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.admin-controls li:hover {
    background-color: #f2f2f2;
}

.control-icon {
    font-size: 60px;
    color: var(--primary-color);
}

.control-title {
    font-size: 13px;
}

/* .rdt_Pagination{
    position: absolute;
    width: 100%;
    bottom:0;
    left:0;
} */
.data-table-parent {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-y: scroll;
}

.data-table-parent::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.actions-parent {
    column-gap: 10px;
}

.actions-parent div,
.actions-parent a {
    height: 30px;
    width: 30px;
    color: white;
    font-size: 16px;
    border-radius: 100px;
    background-color: var(--primary-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.members-profile-head {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background-color: #868383; */
    padding-left: 20px;
    font-weight: 700;
    font-size: 20px;
    column-gap: 10px;
    color: var(--primary-color);
    cursor: pointer;
}

.members-profile-head :hover {
    color: var(--primary-color-darker);
}

.members-name {
    font-size: 20px;
    margin-top: 15px;
}

.profile-medium {
    height: 130px;
    width: 130px;
    background-color: #b5b5b5;
    border-radius: 100px;
    background-image: url(../../assets/profile.png);
    background-size: cover;
}

.members-details {
    /* border: 1px solid #eee; */
    margin-top: 10px;
    font-size: 13px;

}

.members-details tr {
    height: 30px;
}

.members-details tr td:first-child {
    text-align: end;
    font-weight: 400;
    color: #b5b5b5;
    padding-right: 20px;
}

.members-details tr td:nth-child(2) {
    text-align: start;
    max-width: 200px;
    color: #555
}

.modal-bg {
    background-color: #6b6b6b8f;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    height: 430px;
    width: 400px;
    border-radius: 20px;
    position: relative;
    overflow: visible !important;
}

.modal-close {
    align-self: flex-end;
    /* margin-right: 30px; */
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: var(--primary-color);
    position: absolute;
    right: 20px;
    top: -20px;
    color: white;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid white;
    cursor: pointer;
    transition: .5s;
}
.modal-close:hover{
    background-color: var(--primary-color-darker);
    height: 52px;
    width: 52px;
}

.select-transparent {
    border: 1px solid #d4d4d4;
    padding-right: 19px;
    padding-left: 19px;
    border-radius: 5px;
    overflow: hidden;
}

.select-transparent select {
    border: 0;
    outline: 0;
    padding: 10px 20px;
    font-size: 17px;
    text-align: center;
}

.checkbox-primary input {
    margin: 0;
    margin-right: 8px;
}

.checkbox-primary {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 15px;
}

.checkbox-primary label {
    margin: 0;
    padding: 0;
}

.datepick-primary {
    padding-top: 20px;
    width: 300px;
    display: flex;
}

.datepick-primary input {
    border: 1px solid #ccc;
    padding: 10px 10px;
    border-radius: 5px;
    flex: 1;
    display: flex;
    margin-left: 15px;
}

.text-area-ptimary textarea {
    border: 1px solid #ccc;
    padding: 10px 10px;
    border-radius: 5px;
    flex: 1;
    display: flex;
    /* margin-left: 15px; */
    width: 350px;
    height: 160px
}
.expandable-notification{
    position: relative;
    cursor: pointer;

}
.expandable-notification span{
    height:18px;
    width: 18px;
    position: absolute;
    bottom: -7px;
    right: -13px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.notifications-list{
    background-color: white;
    /* height:108px; */
    width: 200px;
    position: absolute;
    top: 27px;
    right: -13px;
    /* background-color: var(--primary-color); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 0;
}
.notifications-list li{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}