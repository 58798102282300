.screen-webinars {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: #111;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 50px;
    }

    .webinar-banner {
        height: 300px;
        width: 1200px;
        border-radius: 20px;
        // background-color: #ccc;
        margin-bottom: 100px;
        background-image: url(../../assets/images/african-bg2.jpg);
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        // 
        position: relative;

        .left {
            flex: 1;
            height: 100%;
        }

        .left {
            background-image: linear-gradient(to right, #b87201, #b87501bf, #65400100);
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            padding: 30px;
        }

        .date-label {
            background-color: #b87201;
            // background-image: url(../../assets/images/african-bg2.jpg);
            width: 100px;
            height: 100px;
            position: absolute;
            left: 100px;
            top: -50px;
            font-size: 25px;
            font-weight: 800;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            line-height: 24px;
            text-align: center;
            border-radius: 20px;
            color: white;
            text-transform: uppercase;
            border: 5px solid white;
        }

        .register-bt {
            position: absolute;
            right: -20px;
            top: 120px;
            background-color: goldenrod;
            height: 50px;
            width: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            color: white;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 3px;
            cursor: pointer;
            border: 5px solid white;
        }

        h1 {
            // background-color: white;
            display: inline-block;
            color: #ffffff;
            width: 630px;
            text-align: left;
            // margin: 0;
            // padding: 10px;
        }

        h4 {
            color: #ffffff;
        }

        p {
            // background-color: white;
            color: #ffffff;
            width: 550px;
            // padding: 10px;
        }
    }
    @media only screen and (max-width : 550px) {
        h1 {
            font-size: 12px;
        }
        .webinar-banner {
            height: 420px;
            width: 80vw;
            position: relative;
        }
        .webinar-banner  h1,.webinar-banner p {
            width: 60vw;
        }
        .webinar-banner  h1{
            font-size: 28px !important;
        }
        .top-title{
            margin-bottom:70px;
            font-size: 27px !important;
        }
        .top-info{
            display: none;
        }
        .webinar-banner .register-bt {
            position: absolute;
            bottom: 20px;
            top: unset;
            background-color: white;
            color: #111;
            width: 70%;
            left: calc(50% - 35%);
        }
        .webinar-banner  .date-label{
            left: calc(50% - 50px);
        }
    }
}