body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.text-left {
text-align: left;
}
:root {
  --primary-color:#ff8500;
  --primary-color-darker:#ff5100;
}

*{
  box-sizing: border-box;
}
.noPadding{
  margin:0;
  padding:0
}
.noDisplay{
  display: none;
}
.golden{
  background-image: var(--gold-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: content;
  background-size: cover;
  background-position: top;
  color: transparent;
}
.bg-white{
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  overflow: hidden;
}
.hor-flex{
  display: flex;
  flex-direction: row;
}
.ver-flex{
  display: flex;
  flex-direction: column;
}
.centered {
  justify-content: center;
  align-items: center;
}
.primary-color{
  color: var(--primary-color) !important;
}