.webinar-rl {
    display: flex;
    width: 1250px;
    margin-top: 0px;
    column-gap: 50px;
    flex-direction: column;
    align-items: center;

    h1 {
        letter-spacing: -1px;
        font-size: 40px;
        padding-bottom: 0px;
    }

    a {
        color: #867904;
        text-decoration: unset;
    }

    p {
        color: #1a1f36;
        margin: 0;
        margin-bottom: 20px;
    }

    .formbg-outer {
        width: 500px;
    }

    .login-root {
        background: #fff;
        display: flex;

        min-height: 100vh;
        overflow: hidden;
    }

    .loginbackground {
        min-height: 692px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        overflow: hidden;
    }

    .flex-flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .center-center {
        align-items: center;
        justify-content: center;
    }

    .box-root {
        box-sizing: border-box;
    }

    .flex-direction--column {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    // .loginbackground-gridContainer {
    //     display: -ms-grid;
    //     display: grid;
    //     -ms-grid-columns: [start] 1fr [left-gutter] (86.6px)[16] [left-gutter] 1fr [end];
    //     grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
    //     -ms-grid-rows: [top] 1fr [top-gutter] (64px)[8] [bottom-gutter] 1fr [bottom];
    //     grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
    //     justify-content: center;
    //     margin: 0 -2%;
    //     transform: rotate(-12deg) skew(-12deg);
    // }

    .box-divider--light-all-2 {
        box-shadow: inset 0 0 0 2px #e3e8ee;
    }

    .box-background--blue {
        background-color: #5469d4;
    }

    .box-background--white {
        background-color: #ffffff;
    }

    .box-background--blue800 {
        background-color: #212d63;
    }

    .box-background--gray100 {
        background-color: #e3e8ee;
    }

    .box-background--cyan200 {
        background-color: #7fd3ed;
    }

    .padding-top--64 {
        padding-top: 64px;
    }

    .padding-top--24 {
        padding-top: 24px;
    }

    .padding-top--48 {
        padding-top: 48px;
    }

    .padding-bottom--24 {
        padding-bottom: 24px;
    }

    .padding-horizontal--48 {
        padding: 48px;
    }

    .padding-bottom--15 {
        padding-bottom: 15px;
    }


    .flex-justifyContent--center {
        -ms-flex-pack: center;
        justify-content: center;
    }

    .formbg {
        margin: 0px auto;
        width: 100%;
        max-width: 448px;
        background: white;
        border-radius: 4px;
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
    }

    span {
        display: block;
        font-size: 20px;
        line-height: 28px;
        color: #1a1f36;
    }

    label {
        margin-bottom: 10px;
    }

    .reset-pass a,
    label {
        font-size: 14px;
        font-weight: 600;
        display: block;
    }

    .reset-pass>a {
        text-align: right;
        margin-bottom: 10px;
    }

    .grid--50-50 {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
    }

    .field input {
        font-size: 16px;
        line-height: 28px;
        padding: 8px 16px;
        width: 100%;
        min-height: 44px;
        border: unset;
        border-radius: 4px;
        outline-color: rgb(84 105 212 / 0.5);
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    input[type="submit"] {
        background-color: rgb(84, 105, 212);
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
            rgb(84, 105, 212) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
    }

    .field-checkbox input {
        width: 20px;
        height: 15px;
        margin-right: 5px;
        box-shadow: unset;
        min-height: unset;
    }

    .field-checkbox label {
        display: flex;
        align-items: center;
        margin: 0;
    }

    a.ssolink {
        display: block;
        text-align: center;
        font-weight: 600;
    }

    .footer-link span {
        font-size: 14px;
        text-align: center;
    }

    .listing a {
        color: #697386;
        font-weight: 600;
        margin: 0 10px;
    }

    .animationRightLeft {
        animation: animationRightLeft 2s ease-in-out infinite;
    }

    .animationLeftRight {
        animation: animationLeftRight 2s ease-in-out infinite;
    }

    .tans3s {
        animation: animationLeftRight 3s ease-in-out infinite;
    }

    .tans4s {
        animation: animationLeftRight 4s ease-in-out infinite;
    }

    @keyframes animationLeftRight {
        0% {
            transform: translateX(0px);
        }

        50% {
            transform: translateX(1000px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    @keyframes animationRightLeft {
        0% {
            transform: translateX(0px);
        }

        50% {
            transform: translateX(-1000px);
        }

        100% {
            transform: translateX(0px);
        }
    }


    .meeting-link {
        font-weight: 700;
        color: #5469d4;
        border: 1px solid #697386;
        padding: 10px;
        width: 300px;
        text-align: center;
        cursor: pointer;
    }

    .iinfo {
        width: 300px;
        text-align: center;
    }

    @media only screen and (max-width : 550px) {
        .formbg-outer {
            width: 90vw;
        }

        .success-image {
            width: 200px;
        }

        .iinfo {
            width: 300px;
        }

        .top-title {
            width: 300px;
            margin-bottom: 0px;
        }
    }


    .registered-button {
        // width: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        font-size: 17px !important;
    }
}

@media only screen and (max-width : 550px) {
    .webinar-rl {
        width: unset;
    }
}